var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            { staticClass: "mx-auto", staticStyle: { width: "90%" } },
            [
              _c(
                "v-row",
                [
                  _c("v-card-title", { staticClass: "font-weight-bold pb-3" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("title.contractor.explanImportantMatters")
                        ) +
                        "\n        "
                    )
                  ])
                ],
                1
              ),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("description.explanImportantMatters.explanation1")
                    )
                  )
                ])
              ]),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "description.explanImportantMatters.explanation2"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", { staticClass: "my-0" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "description.explanImportantMatters.explanation3"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", { staticClass: "mt-0" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "description.explanImportantMatters.explanation4"
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("description.explanImportantMatters.explanation5")
                    )
                  )
                ])
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black mx-auto",
                      staticStyle: { "font-size": "16px" },
                      attrs: {
                        align: "center",
                        "max-width": "400px",
                        width: "85vw",
                        color: "download",
                        dark: "",
                        rounded: ""
                      },
                      on: { click: _vm.download }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.download")))]
                  )
                ],
                1
              ),
              _c("v-row", { staticClass: "pt-8" }, [
                _c(
                  "div",
                  {
                    staticClass: "text-center",
                    staticStyle: { margin: "0 auto" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checked,
                          expression: "checked"
                        }
                      ],
                      attrs: {
                        disabled: _vm.checkDisabled,
                        type: "checkbox",
                        id: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.checked)
                          ? _vm._i(_vm.checked, null) > -1
                          : _vm.checked
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.checked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checked = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checked = $$c
                          }
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "checkbox" } }, [
                      _vm._v(_vm._s(_vm.$t("checkbox.agree")))
                    ])
                  ]
                )
              ]),
              _c(
                "v-row",
                [
                  _c("v-card-title", { staticClass: "font-weight-bold pb-3" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.reviewContract")) +
                        "\n        "
                    )
                  ])
                ],
                1
              ),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("description.reviewContract.explanation1"))
                  )
                ])
              ]),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("description.reviewContract.explanation2"))
                  )
                ])
              ]),
              _c("v-row", { staticClass: "pl-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("description.reviewContract.explanation3"))
                  )
                ])
              ]),
              _c(
                "v-row",
                { staticClass: "my-12" },
                [
                  _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            width: "30vw",
                            "max-width": "170px",
                            color: "back",
                            dark: "",
                            rounded: ""
                          },
                          on: { click: _vm.backPage }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.back")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black white--text mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            disabled: !_vm.checked,
                            width: "30vw",
                            "max-width": "170px",
                            color: "next",
                            rounded: ""
                          },
                          on: { click: _vm.goNextPage }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.next")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }